<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="secondary">
          <ion-card-title class="ion-text-center" size="large">{{title}}</ion-card-title>
        </ion-card-header>
      <ion-card-content>
        <!-- List Inset Lines -->
        <ion-list lines="inset">
          <ion-item>
            <div class="disp-block">
              <ion-label>得意先</ion-label>
              <ion-note slot="end">{{tokuiName}}</ion-note>
            </div>
          </ion-item>
          <ion-item>
            <div class="disp-block">
              <ion-label>現場名</ion-label>
              <ion-note slot="end">{{genbaName}}</ion-note>
            </div>
          </ion-item>
          <ion-item>
            <div class="disp-block">
              <ion-label>住所</ion-label>
              <ion-note slot="end">{{address}}</ion-note>
            </div>
          </ion-item>
          <ion-item>
            <div class="disp-block">
              <ion-label>コンテナ番号</ion-label>
              <ion-note slot="end">{{contCd}}</ion-note>
            </div>
          </ion-item>
          <ion-item v-if="protype!=0">
            <div class="disp-block">
              <ion-label>設置日</ion-label>
              <ion-note slot="end">{{dateFormat(startDate)}}</ion-note>
            </div>
          </ion-item>
        </ion-list>

        <ion-button v-if="protype!=2" id="kakutei" color="primary" expand="block" disabled="false" @click="clickKakutei">確定</ion-button>
        <ion-button id="kakutei-back" color="warning" @click="backPage">戻る</ion-button>
      </ion-card-content>
      </ion-card>
    </ion-content>
    <ion-modal
      :is-open="isOpenRef"
      css-class="my-custom-class"
      @onDidDismiss="setOpen(false)"
      show-backdrop="true"
    >
      <Modal :msg="secchiMsg" v-on:close-modal="closeModal" v-on:show-setting="showSetting"></Modal>
    </ion-modal>
    <ion-modal
      :is-open="isOpenSettingRef"
      css-class="my-setting-class"
      @onDidDismiss="setSettingOpen(false)"
      show-backdrop="true"
    >
      <AllowAccess v-on:close-modal="closeModal"></AllowAccess>
    </ion-modal>
  </ion-page>
</template>

<style scoped>
.disp-block {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>

<script>

import { IonContent, IonPage, IonCardTitle, IonLabel, IonCard, IonCardContent, IonButton, IonList, IonItem, IonNote, alertController, modalController, IonModal } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import Modal from '../components/Modal.vue'
import AllowAccess from '../components/AllowAccess.vue'

export default defineComponent({
  name: 'Kakutei',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonLabel,
    IonCard,
    IonCardContent,
    IonButton,
    IonList,
    IonItem,
    IonNote,
    Modal,
    AllowAccess,
    IonModal,
  },
  props:['title', 'protype'],
  setup() {
    const isOpenRef = ref(false);
    const isOpenSettingRef = ref(false);
    const setOpen = (state) => isOpenRef.value = state;
    const setSettingOpen = (state) => isOpenSettingRef.value = state;
    const secchiMsg = '設置手続きを続けるには位置情報へのアクセスを許可する必要があります。';
    return { isOpenRef, isOpenSettingRef, setOpen, setSettingOpen, secchiMsg }
  },
  data(){
      return {
        tokuiName:'',
        genbaName:'',
        address:'',
        contCd:'',
        startDate:'',
        lat:'',
        lng:'',
        rentNo:'',
        errFlg:false,
      };
  },
  ionViewDidEnter() {
    this.setContInfo();
  },
  mounted() {
    this.setContInfo();
  },
  methods: {
    setContInfo: function () {
      this.tokuiName = this.$route.params.tokuiName || '';
      this.genbaName = this.$route.params.genbaName || '';
      this.address = this.$route.params.address || '';
      this.contCd = this.$route.params.contCd;
      this.startDate = this.$route.params.startDate;
      
      if(this.tokuiName == 'null'){
        this.tokuiName = '';
      }
      if(this.genbaName == 'null'){
        this.genbaName = '';
      }
      if(this.address == 'null'){
        this.address = '';
      }
    },
    clickKakutei: async function () {
      if(this.protype==0){
        await this.getPosition();
      }else if(this.protype==1){
        this.axios.put(`get-continfo/${this.contCd}`, { cont_cd: this.contCd, p_type: this.protype}).then(res => {
        if(res.data["result"] == "success"){
          this.rentNo = res.data["rent_no"];
          
          // メール送信
          this.axios.post('mail', { mobaile_type: 0, rent_no: this.rentNo, p_type: this.protype });
          
          this.$router.push({name: 'Kanryo', params: { title: this.title }});
        }
      });
      }
    },
    backPage: function () {
      this.$router.back();
    },
    getPosition: function(){
      navigator.geolocation.getCurrentPosition(this.get_coord, this.err);
    },
    get_coord: function (position) {

      var lat = position.coords.latitude;
      var lng = position.coords.longitude;

      this.axios.put(`get-continfo/${this.contCd}`, { cont_cd: this.contCd, p_type: this.protype, lat: lat, lng: lng }).then(res => {
        if(res.data["result"] == "success"){
          this.rentNo = res.data["rent_no"];
          
          // メール送信
          this.axios.post('mail', { mobaile_type: 0, rent_no: this.rentNo, p_type: this.protype });

          this.$router.push({name: 'Kanryo', params: { title: this.title }});
        }
      });
    },
    err: function () {
      this.setOpen(true);
    },
    presentAlert: async function () {
      const alert = await alertController.create({
        header: 'エラー',
        message: `<span>設置手続きを行うには位置情報へのアクセスを許可する必要があります。</span><br/><br/>設定方法は<a id='show'>こちら</a>から確認できます。`,
        buttons: ['閉じる']
      });

      await alert.present();
    },
    async openModal() {
      const modal = await modalController
        .create({
          component: Modal,
          cssClass: 'my-custom-class',
          componentProps: {
            title: 'エラー',
          },
        })
      return modal.present();
    },
    closeModal: function () {
      modalController.dismiss();
    },
    showSetting: function () {
      modalController.dismiss();
      this.$nextTick(() => {
        this.setSettingOpen(true);
      })
    }
  }
});


</script>

<style scoped>
#kakutei {
  margin-top: 20px;
  margin-left:20px;
  margin-right:20px;
}
#kakutei-back {
  margin:20px;
}
#title {
  width:100%;
  margin-left:auto;
  margin-right:auto;
}
#tokui-text {
  font-size:20px;
  text-decoration: underline;
  color: rgb(25, 148, 170);
}
.setting {
  cursor: pointer;
}
</style>