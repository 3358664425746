<template>
  <ion-header>
    <ion-toolbar>
	<ion-title>{{ title }}</ion-title>
	<ion-buttons slot="end">
		<ion-button @click="closeModal()"><ion-icon name="close"></ion-icon></ion-button>
	</ion-buttons>
	<!-- <div class="f-right"><ion-button @click="closeModal" :class="['close-button']">閉じる</ion-button></div> -->
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
	<ion-slides pager="true" scrollbar="true" :options="slideOpts">
		<ion-slide>
			<h1>設定方法1</h1>
		</ion-slide>
		<ion-slide>
			<h1>設定方法2</h1>
		</ion-slide>
		<ion-slide>
			<h1>設定方法3</h1>
		</ion-slide>
	</ion-slides>
  </ion-content>
</template>

<style>

.close-button {
	background: gray !important;
	margin-right: 5px;
}
.my-setting-class .modal-wrapper {
  width: 90%;
  height: 90%;
  position:absolute;
  top:5%;
  display:block;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-color: black;
  border: solid;
}
ion-slides {
	margin-top: 20px !important;
}
ion-slide {
	margin-bottom: 300px;
}
.swiper-pagination.swiper-pagination-bullets {
	bottom: 0px !important;
}
.swiper-scrollbar {
	bottom: -5px !important;
}

</style>
<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonButtons, IonIcon, IonSlides, IonSlide } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AllowAccess',
  props: {
    title: { type: String, default: '位置情報利用設定' },
    close: { type: Function },
  },
  setup() {
    // Optional parameters to pass to the swiper instance. See http://idangero.us/swiper/api/ for valid options.
    const slideOpts = {
      initialSlide: 0,
      speed: 400
    };
    return { slideOpts }
  },
  data() {
    return {
    }
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonButtons, IonIcon, IonSlides, IonSlide },
  methods: {
    closeModal: function () {
      console.log('close');
      this.$emit('close-modal');
    },
  }
});
</script>