<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="secondary">
          <ion-card-title class="ion-text-center" size="large">{{title}}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <div id="video-input">
              <video id="video" autoplay playsinline width="300" height="300"></video>
              <canvas id="canvas" width="300" height="300"></canvas>
          </div>
         <ion-button id="back" color="warning" @click="backPage">戻る</ion-button>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonCardTitle, IonCard, IonCardContent, IonButton, alertController } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Readqr',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonCard,
    IonCardContent,
    IonButton,
  },
  props:['title', 'protype'],
  data(){
      return {
        url:'',
        player: '',
        canvas: '',
        inpCont:'',
        tokuiCont:'',
        tokuiName: '',
        genbaName: '',
        address: '',
        startDate: '',
        contCd: '',
        errMsg: '',
      };
  },
  ionViewDidEnter() {
    this.initVideo();
  },
  mounted(){
    this.initVideo();
  },
  methods: {
    initVideo: function () {
      this.player = document.getElementById('video');
      this.canvas = document.getElementById('canvas');
      
      if (navigator.mediaDevices) {
          navigator.mediaDevices.getUserMedia({
              audio: false,
              video: {
                width: 300,
                height: 300,
                facingMode: "environment"  
              }
          })
              .then(this.handleSuccess)
              .catch(function (err) {
                  console.log(JSON.stringify(err));
                  console.log('handle err');
              });
      } else {
          alert('ビデオカメラを使用できません');
      }
    },
    startScan: function (callback) {
      let self = this;
      const width = this.canvas.width;
      const height = this.canvas.height;
      var canvasContext = this.canvas.getContext('2d');

      var intervalHandler = setInterval(function () {
          if(canvasContext!=null){
            canvasContext.drawImage(self.player, 0, 0, width, height);
            var imageData = canvasContext.getImageData(0, 0, width, height);
            var scanResult = window.jsQR(imageData.data, imageData.width, imageData.height);
          }
          if (scanResult) {
              clearInterval(intervalHandler);
              callback(scanResult);
              self.drawLine(canvasContext, scanResult.location);

              // video と canvas を入れ替え
              self.canvas.style.display = 'block';
              self.player.style.display = 'none';
              self.player.pause();

              self.player.srcObject.getTracks().forEach(function(track) {
                track.stop();
              });
          }
      }, 200);
    },
    handleSuccess: function (stream) {
        var self = this
        self.player.srcObject = stream;

        self.startScan(async function (scanResult) {

          await self.axios.get('get-continfo', { params: {'qr_inf': scanResult.data, 'p_type': self.protype } }).then(res => {
            if(res.data["result"] == "success"){
              self.tokuiName = res.data["tokui_name"];
              self.genbaName = res.data["genba_name"];
              self.address = res.data["address"];
              self.startDate = res.data["start_date"];
              self.contCd = res.data["cont_cd"];

              // カメラの停止
              if(self.player.srcObject!==null){
                      self.player.pause();
                      self.player.srcObject.getTracks().forEach(function(track) {
                        track.stop();
                      });
                    }
              self.$router.push({name: 'Kakutei', params: { 
                title: self.title,
                protype: self.protype,
                tokuiName: self.tokuiName,
                genbaName: self.genbaName,
                address: self.address,
                startDate: self.startDate,
                contCd: self.contCd
              }})
            }else{
              self.errMsg = res.data["msg"];
              self.presentAlert();
            }
          });
        });
    },
    drawLine: function (ctx, pos, options={color:"blue", size:5}) {
      // 線のスタイル設定
      ctx.strokeStyle = options.color;
      ctx.lineWidth   = options.size;

      // 線を描く
      ctx.beginPath();
      ctx.moveTo(pos.topLeftCorner.x, pos.topLeftCorner.y);         // 左上からスタート
      ctx.lineTo(pos.topRightCorner.x, pos.topRightCorner.y);       // 右上
      ctx.lineTo(pos.bottomRightCorner.x, pos.bottomRightCorner.y); // 右下
      ctx.lineTo(pos.bottomLeftCorner.x, pos.bottomLeftCorner.y);   // 左下
      ctx.lineTo(pos.topLeftCorner.x, pos.topLeftCorner.y);         // 左上に戻る
      ctx.stroke();
    },
    presentAlert: async function () {
      const alert = await alertController.create({
        header: 'エラー',
        message: this.errMsg,
        buttons: ['閉じる']
      });

      await alert.present();
    },
    backPage: function(){
      if(this.player.srcObject!==null){
        this.player.pause();
        this.player.srcObject.getTracks().forEach(function(track) {
          track.stop();
        });
      }
      this.$router.back();
    },
  },
});
</script>

<style scoped>
#video, #canvas {
  justify-content:center;
  margin-top:20px;
  margin-left:auto;
  margin-right:auto;
}
#canvas {
  display:none;
}
#qr {
  margin-left:10px;
}
.tokui {
  margin-left:20px;
}
#next, #contnext {
  margin-left:20px;
  margin-right:20px;
}
#back, #contback {
  margin:20px;
}
#title {
  width:100%;
  margin-left:auto;
  margin-right:auto;
}
.tokui-text {
  font-size:20px;
  text-decoration: underline;
  color: rgb(25, 148, 170);
}
.conte {
  margin:20px;
}
.inp-cont {
  border: solid 0.5px;
  border-color: #ddd;
  border-radius: 5px;
}
</style>