<template>
  <ion-page>
    <ion-content :fullscreen="true">
     <ion-card style="margin-top:70px">
        <ion-card-header color="secondary">
            <ion-card-title class="ion-text-center" size="large">MENU</ion-card-title>
        </ion-card-header>
      <ion-card-content>
      <div id="container">
        <div class="menubtn">
            <ion-button color="primary" expand="block" @click="clickHaichi">設 置</ion-button>
        </div>
        <div class="menubtn">
            <ion-button color="primary" expand="block" @click="clickTekkyo">引上げ</ion-button>
        </div>
        <div class="menubtn">
            <ion-button color="primary" expand="block" @click="clickKakunin">確 認</ion-button>
        </div>
        <div class="menubtn">
            <ion-button color="primary" expand="block" @click="clickJokyo">状 況</ion-button>
        </div>
      </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonCardTitle, IonButton, IonCard, IonCardContent } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Menu',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonButton,
    IonCard,
    IonCardContent
  },
  data(){
      return {
      };
  },
  mounted(){
  },
  methods:{
    // 配置ボタン
    clickHaichi(){
        this.$router.push({name: 'Select', params: {title: '設置', protype: 0}});
    },
    // 撤去ボタン
    clickTekkyo(){
        this.$router.push({name: 'Select', params: {title: '引上げ', protype: 1}});
    },
    // 確認ボタン
    clickKakunin(){
        this.$router.push({name: 'Select', params: {title: '確認', protype: 2}});
    },
    // 状況ボタン
    clickJokyo(){
        this.$router.push("/jokyo");
    }
  }
});
</script>

<style scoped>
  .menubtn{
    margin:20px;
  }
</style>