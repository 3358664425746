<template>
  <ion-header>
    <ion-toolbar>
	<ion-title>{{ title }}</ion-title>
	<ion-buttons slot="end">
		<ion-button @click="closeModal()"><ion-icon name="close"></ion-icon></ion-button>
	</ion-buttons>
	<!-- <div class="f-right"><ion-button @click="closeModal" :class="['close-button']">閉じる</ion-button></div> -->
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
	<div>
    {{ msg }}
	</div>
	<div class="show-setting">
		<a  @click="showSetting">設定方法を表示する</a>
	</div>
  </ion-content>
</template>

<style>

.show-setting {
	cursor: pointer;
	text-align: center;
	margin-top: 10px;
}

.close-button {
	background: gray !important;
	margin-right: 5px;
}
.my-custom-class .modal-wrapper {
  width: 80%;
  height: 180px;
  position:absolute;
  top:5%;
  display:block;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

</style>
<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonButtons, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Modal',
  props: {
    title: { type: String, default: '警告' },
    msg: { type: String, default: 'msg' },
  },
  data() {
    return {
      content: '設置手続きを続けるには位置情報へのアクセスを許可する必要があります。',
    }
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonButtons, IonIcon },
  methods: {
    showSetting: function () {
      this.$emit('show-setting');
    },
    closeModal: function () {
      this.$emit('close-modal');
    }
  }
});
</script>