<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card style="margin-top:70px">
        <ion-card-content>
          <ion-item>
            端末を登録する必要があります。
          </ion-item>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonCard, IonCardContent } from '@ionic/vue';
import { defineComponent } from 'vue';
//import axios from 'axios';

export default defineComponent({
  name: 'Login',
  components: {
    IonContent,
    IonPage,
    IonCard,
    IonCardContent
  },
  data(){
      return {
        token: ''
      };
  },
  mounted(){
    var deviceCd = localStorage.getItem('device_cd');
    if(deviceCd === null || deviceCd === '') {
      deviceCd = this.$route.query.devicecd;
      localStorage.setItem('device_cd', deviceCd)
    }

    // manifest.jsonの作成
    this.setManifestURL(deviceCd);

    // 認証処理
    var accessDate = this.get_now_ymd();
    this.axios.post('mobile-login', {device_cd: deviceCd, access_date: accessDate}).then(res => { 
       if(res.data == 'authrized') {
         this.$router.push("/menu");
       }
      });
  },
  methods:{
      get_now_ymd: function () {
        var dt = new Date();
        var y = dt.getFullYear();
        var m = ("00" + (dt.getMonth()+1)).slice(-2);
        var d = ("00" + dt.getDate()).slice(-2);
        var result = y + "/" + m + "/" + d;
        return result;
      },
      setManifestURL: function (token) {
        var baseUrl = 'https://mobile.web-container.net';
        var startUrl = token ? baseUrl + '?devicecd=' + token : baseUrl;

        var manifest = {
          "name": "Web-Container",
          "short_name": "モバイル担当者システム",
          "icons": [
              {
                  "src": baseUrl + "/img/icons/sms-192x192.png",
                  "sizes": "192x192",
                  "type": "image/png"
              },
              {
                  "src": baseUrl + "/img/icons/sms-512x512.png",
                  "sizes": "512x512",
                  "type": "image/png"
              },
              {
                  "src": baseUrl + "/img/icons/sms-192x192.png",
                  "sizes": "192x192",
                  "type": "image/png",
                  "purpose": "maskable"
              },
              {
                  "src": baseUrl + "/img/icons/sms-512x512.png",
                  "sizes": "512x512",
                  "type": "image/png",
                  "purpose": "maskable"
              }
          ],
          "start_url": startUrl,
          "display": "standalone",
          "background_color": "#000000",
          "theme_color": "#4DBA87"
        }

        const stringManifest = JSON.stringify(manifest);
        const blob = new Blob([stringManifest], { type: 'application/json'});
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector('#my-manifest').setAttribute('href', manifestURL);
    }
  }
});
</script>

<style scoped>
.toroku {
  margin:20px;
}
</style>