<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="secondary">
            <ion-card-title class="ion-text-center" size="large">{{ title }}</ion-card-title>
        </ion-card-header>
      <ion-card-content>
        <div id="container">
          <div class="menubtn">
              <ion-button color="primary" expand="block" @click="clickQr()">QRコード読取</ion-button>
          </div>
          <div class="menubtn">
              <ion-button color="primary" expand="block" @click="clickCont()">コンテナ識別番号手入力</ion-button>
          </div>
          <div class="menubtn">
              <ion-button color="warning" @click="clickBack()">戻る</ion-button>
          </div>

        </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonCardTitle, IonButton, IonCard, IonCardContent } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Select',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonButton,
    IonCard,
    IonCardContent
  },
  props: ['title', 'protype'],
  data(){
      return {
      };
  },
  mounted(){
  },
  methods:{
    // 配置ボタン
    clickQr(){
        this.$router.push({name: 'Readqr', params: {title: this.title, protype: this.protype}});
    },
    // 撤去ボタン
    clickCont(){
        this.$router.push({name: 'Inpcont', params: {title: this.title, protype: this.protype}});
    },
    // 戻るボタン
    clickBack(){
      this.$router.back();
    }
  }
});
</script>

<style scoped>
  .menubtn{
    margin:20px;
  }
</style>