<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="secondary">
          <ion-card-title class="ion-text-center" size="large">端末登録</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <!-- List Inset Lines -->
          <ion-item class="ion-text-center">承認しますか</ion-item>
          <ion-button id="kakutei" color="primary" expand="block" @click="clickShonin">承認</ion-button>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonContent, IonPage, IonCardTitle, IonCard, IonButton, IonItem } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Shonin',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonCard,
    IonButton,
    IonItem
  },
  data(){
      return {
        device_cd:'',
      };
  },
  mounted() {
    // urlからデバイスコードを取得
    this.deviceCd = this.$route.query.devicecd;
    console.log('device_cd' + this.deviceCd)
    console.log(this.$route)

    var access_date = this.get_now_ymd();
    this.axios.post('mobile-login', {device_cd: this.deviceCd, access_date: access_date}).then(res => { 
      if(res.data == 'unauthrized') {
        this.$router.push("/login");
      }
    });

  },
  methods: {
    clickShonin: function () {

      this.axios.post('token-approval', {
        'device_cd': this.deviceCd,
      }).then((res) => {
        if(res.result === 1){
          alert('fail');
        }else{
          localStorage.setItem('device_cd', this.deviceCd);
          this.setManifestURL(this.deviceCd);
          this.$router.push("/kakunin");
        }
      })
    },
    get_now_ymd: function () {
        var dt = new Date();
        var y = dt.getFullYear();
        var m = ("00" + (dt.getMonth()+1)).slice(-2);
        var d = ("00" + dt.getDate()).slice(-2);
        var result = y + "/" + m + "/" + d;
        return result;
    },
    setManifestURL: function (token) {
      var baseUrl = 'https://mobile.web-container.net';
      var startUrl = token ? baseUrl + '?devicecd=' + token : baseUrl;

      var manifest = {
        "name": "Web-Container",
        "short_name": "モバイル担当者システム",
        "icons": [
            {
                "src": baseUrl + "/img/icons/sms-192x192.png",
                "sizes": "192x192",
                "type": "image/png"
            },
            {
                "src": baseUrl + "/img/icons/sms-512x512.png",
                "sizes": "512x512",
                "type": "image/png"
            },
            {
                "src": baseUrl + "/img/icons/sms-192x192.png",
                "sizes": "192x192",
                "type": "image/png",
                "purpose": "maskable"
            },
            {
                "src": baseUrl + "/img/icons/sms-512x512.png",
                "sizes": "512x512",
                "type": "image/png",
                "purpose": "maskable"
            }
        ],
        "start_url": startUrl,
        "display": "standalone",
        "background_color": "#000000",
        "theme_color": "#4DBA87"
      }

      const stringManifest = JSON.stringify(manifest);
      const blob = new Blob([stringManifest], { type: 'application/json'});
      const manifestURL = URL.createObjectURL(blob);
      document.querySelector('#my-manifest').setAttribute('href', manifestURL);
    }
  }
});
</script>

<style scoped>
#kakutei {
  margin:20px;
}
#back {
  margin:20px;
}
#title {
  width:100%;
  margin-left:auto;
  margin-right:auto;
}
#tokui-text {
  font-size:20px;
  text-decoration: underline;
  color: rgb(25, 148, 170);
}
</style>