<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="secondary">
          <ion-card-title class="ion-text-center" size="large">状況</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-card class="map-disp">
              <div id="map"></div>
          </ion-card>
          <div class="tokui">
            <div>
              <ion-label class="genba-text">現場名</ion-label><br>
              <ion-input v-bind:value="genbaName? genbaName: address" type="text" class="disp-genba" size="30" readonly></ion-input>
            </div>
            <div>
              <ion-label class="genba-text">設置日</ion-label><br>
              <ion-input type="text" class="disp-date" size="30" readonly>{{ startDate? dateFormat(startDate) + '(' + days + ')' : '' }}</ion-input>
            </div>
          </div>
          <ion-button id="keiro" color="primary" expand="block" @click="clickKeiro" disabled>経路</ion-button>
          <ion-button id="jokyo-back" color="warning" @click="backPage">戻る</ion-button>
        </ion-card-content>
      </ion-card>
    </ion-content>
    <ion-modal
      :is-open="isOpenRef"
      css-class="my-custom-class"
      @onDidDismiss="setOpen(false)"
      show-backdrop="true"
    >
      <Modal :msg="keiroMsg" v-on:close-modal="closeModal" v-on:show-setting="showSetting"></Modal>
    </ion-modal>
    <ion-modal
      :is-open="isOpenSettingRef"
      css-class="my-setting-class"
      @onDidDismiss="setSettingOpen(false)"
      show-backdrop="true"
    >
      <AllowAccess v-on:close-modal="closeModal"></AllowAccess>
    </ion-modal>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonCardTitle, IonLabel, IonInput, IonCard, IonCardContent, IonButton, alertController, IonModal, modalController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import "leaflet/dist/leaflet.css"
import L from "leaflet";
require("leaflet.markercluster")
require("leaflet-easybutton")
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet-easybutton/src/easy-button.css';
import '@fortawesome/fontawesome-free/css/all.css';
import Modal from '../components/Modal.vue';
import AllowAccess from '../components/AllowAccess.vue';


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default defineComponent({
  name: 'Jokyo',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonLabel,
    IonInput,
    IonCard,
    IonCardContent,
    IonButton,
    Modal,
    AllowAccess,
    IonModal,
  },
  setup() {
    const isOpenRef = ref(false);
    const isOpenSettingRef = ref(false);
    const setOpen = (state) => isOpenRef.value = state;
    const setSettingOpen = (state) => isOpenSettingRef.value = state;
    const keiroMsg = '経路を表示するには位置情報へのアクセスを許可する必要があります。';
    return { isOpenRef, isOpenSettingRef, setOpen, setSettingOpen, keiroMsg }
  },
  data(){
      return {
        url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
        mapWidth: 500,
        sepaPx:50,
        zoom: 15,
        center: [26.214923	, 127.674171],
        markers:[],
        markerGroup:[],
        genbaName:'',
        address:'',
        startDate: '',
        days: '',
        currentLat:'',
        currentLng:'',
        lat:'',
        lng:'',
        markerUpdate: true,
        map: {},
        layer: {},
        groupLayer: {},
        mapIconGreen: {},
        mapIconRed: {},
      };
  },
  mounted(){
    //var self = this;
    //self.getPosition();

    //---- アイコンを作成
    this.mapIconRed = L.icon({
        iconUrl: "assets/img/marker-red-icon.png",
        iconRetinaUrl: "assets/img/marker-red-icon-2x.png",
        shadowUrl: "assets/img/marker-shadow.png",
        iconSize:[25,41],
        shadowSize: [41, 41],
        iconAnchor: [13, 40],
        shadowAnchor: [12, 40],
        popupAnchor: [0, -32]
    });

    this.$nextTick(
      () => {
        setTimeout(()=>{
        this.layer = new L.tileLayer(this.url, {opacity:0.7});
        this.map = new L.Map("map", {
          center: L.latLng(26.214923, 127.675171),
          zoom: 15,
        });
        this.layer.addTo(this.map)
        this.addMarker();
        L.easyButton('fa-external-link-alt', () => {
          this.presentAlert();
        }).addTo(this.map)
        },500)
      }
    )

   
  },
  methods: {
    addMarker: async function () {
      var self = this;
      await self.axios.get('map').then((res) => {
        self.markers = res.data.map;
        // self.groupLayer = new L.featureGroup()
        this.groupLayer = new L.markerClusterGroup({
          iconCreateFunction: function (cluster) {
            var markers = cluster.getAllChildMarkers();
            var kaishu_flg = false
            for (var i = 0; i < markers.length; i++) {
             if (markers[i].options.rent_status == 2) {
               kaishu_flg = true
             }
             var kaishu = kaishu_flg? 'red' : 'blue';
             console.log(kaishu)
            }
            return L.divIcon({ html: '<div><span>' + markers.length + '</span></div>', className: 'marker-cluster marker-cluster-' + kaishu, iconSize: L.point(40, 40) });
          }
        });
        for ( let key in self.markers) {
          if(self.markers[key].status == 2){
            self.groupLayer.addLayer(new L.Marker([self.markers[key].lat, self.markers[key].lng], {icon: this.mapIconRed, rent_status: 2}).on('click', () => {
              self.clickMark(self.markers[key]);
            }))
          }else{
            self.groupLayer.addLayer(new L.Marker([self.markers[key].lat, self.markers[key].lng], {rent_status: 1}).on('click', () => {
              self.clickMark(self.markers[key]);
            }))
          }
        }
        self.groupLayer.addTo(self.map)
        self.map.fitBounds(self.groupLayer.getBounds());
      })
    },
    clickMark: function (mark) {
      this.genbaName = mark.genba_name;
      this.address = mark.address;
      this.startDate = mark.rent_start_date;
      this.days = mark.rent_days;
      this.currentLat = mark.lat;
      this.currentLng = mark.lng;
      console.log(mark)

      const button = document.getElementById('keiro');
      button.disabled = false;
    },
    clickKeiro: function(){
      this.getPosition();
    },
    backPage: function(){
      this.$router.back();
    },
    getPosition:function(){
      navigator.geolocation.getCurrentPosition(this.get_coord, this.err);
    },
    get_coord: function (position) {
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;

      window.location.href=`https://www.google.com/maps/dir/?api=1&origin=${this.lat},${this.lng}&destination=${this.currentLat},${this.currentLng}&travelmode=driving`;
    },
    err: function () {
      this.setOpen(true);
    },
    presentAlert: async function () {
      const alert = await alertController
        .create({
          header: '確認',
          message: 'Google Mapで開きますか？',
          buttons: ['キャンセル',
                    { text: 'OK',
                      role: 'cancel',
                      handler: () => {
                        this.openGmap();
                      }}
                  ]
        });
       await alert.present();
    },
    openGmap: function () {
      var curCenter = this.map.getCenter();
      var curZoom = this.map.getZoom();

      window.location.href=`https://www.google.com/maps/@?api=1&map_action=map&center=${curCenter.lat},${curCenter.lng}&zoom=${curZoom}`;
    },
    closeModal: function () {
      console.log('jokyo close')
      modalController.dismiss();
    },
    showSetting: function () {
      modalController.dismiss();
      this.setSettingOpen(true);
    },
  },
});
</script>

<style scoped>
.disp-genba {
  margin-left:10px;
}

.disp-date{
  margin-left:20px;
}

.tokui {
  margin-left:20px;
  margin-top:30px;
}

#keiro {
  margin-left:20px;
  margin-right:20px;
}

#jokyo-back {
  margin:20px;
}

#title {
  width:100%;
  margin-left:auto;
  margin-right:auto;
}

.genba-text {
  font-size:20px;
  text-decoration: underline;
  color: rgb(25, 148, 170);
}

#map {
  width: 100%;
  height:200px;
}

.map-disp {
  width: 90%;
  margin-top:30px;
  margin-left: auto;
  margin-right: auto;
}

</style>