import { createRouter, createWebHistory } from '@ionic/vue-router';
import Login from '../views/Login.vue';
import Shonin from '../views/Shonin.vue';
import Kakunin from '../views/Kakunin.vue';
import Menu from '../views/Menu.vue';
import Select from '../views/Select.vue';
import Readqr from '../views/Readqr.vue';
import Inpcont from '../views/Inpcont.vue';
import Kakutei from '../views/Kakutei.vue';
import Kanryo from '../views/Kanryo.vue';
import Jokyo from '../views/Jokyo.vue';

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login 
  },
  {
    path: '/signup',
    name: 'Shonin',
    component: Shonin 
  },
  {
    path: '/kakunin',
    name: 'Kakunin',
    component: Kakunin 
  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu 
  },
  {
    path: '/select',
    name: 'Select',
    component: Select,
    props: true
  },
  {
    path: '/readqr',
    name: 'Readqr',
    component: Readqr,
    props: true
  },
  {
    path: '/inpcont',
    name: 'Inpcont',
    component: Inpcont,
    props: true
  },
  {
    path: '/kakutei',
    name: 'Kakutei',
    component: Kakutei,
    props: true
  },
  {
    path: '/kanryo',
    name: 'Kanryo',
    component: Kanryo,
    props: true
  },
  {
    path: '/jokyo',
    name: 'Jokyo',
    component: Jokyo 
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
