<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="secondary">
          <ion-card-title class="ion-text-center" size="large">端末登録</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <!-- List Inset Lines -->
          <div class="content">
            <ion-item class="ion-text-center">PC画面で氏名、電話番号を入力して登録を完了してください。</ion-item>
          </div>
          <ion-button id="kakutei" color="primary" expand="block" @click="clickKakutei">MENUへ</ion-button>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonContent, IonPage, IonCardTitle, IonCard, IonButton, IonItem } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Kakunin',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonCard,
    IonButton,
    IonItem
  },
  data(){
      return {
      };
  },
  mounted() {
  },
  methods: {
    clickKakutei: function () {
      this.$router.push("/menu");
    },
  }
});
</script>

<style scoped>
.content {
  margin-top:10px;
}
#kakutei {
  margin:20px;
}
#back {
  margin:20px;
}
#title {
  width:100%;
  margin-left:auto;
  margin-right:auto;
}
</style>